.menu {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 13vh;
  margin-bottom: 10vh;
}
.menu .menuTitle {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 3.5rem;
}

.menuList {
  width: 90vw;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.menuItem {
  border-radius: 15px;
  width: 300px;
  height: auto;
  margin-bottom: 20px;
  box-shadow: 0px 3px 15px rgb(27 106 198 / 20%);
}
.menuItem:hover {
  box-shadow: 0px 3px 15px rgb(27 106 198 / 50%);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.menuItem div {
  border-top-left-radius: 15px;
  border-top-right-radius: 11px;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.menuItem h3 {
  text-align: center;
  margin-top: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

.menuItem h4 {
  text-align: center;
  margin-top: 30px;
}

.menuItem p {
  margin-left: 150px;
  margin-top: 50px;
  font-size: large;
  font-style: bold;
}
