.footer {
  width: 100%;
  height: 80px;
  background-color: #383737;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}

.socialMedia svg {
  color: #1b6ac6;
  font-size: 40px;
  cursor: pointer;
  margin-top: 10px;
}

.footer p {
  color: white;
}
