.contact {
  width: 100%;
  height: 110vh;
  display: flex;
  margin-top: 100px;
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h3 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 10;
  font-size: 50px;
  text-align: center;
}

/* form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 30px;
}

form input {
  height: 40px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}
input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}
form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: black;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
} */

.contact-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contactItem {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plata-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contactCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 300px;
  height: 200px;
  margin: 20px;
  box-shadow: 0px 3px 15px rgb(27 106 198 / 20%);
  color: white;
  background-color: #1b6ac6;
}

.conntactCard a {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@media only screen and (max-width: 650px) {
  .contact {
    flex-direction: column;
  }

  .leftSide {
    display: none;
  }

  .contact-items {
    flex-direction: column;
  }

  .contact .rightSide {
    padding-top: 20px;
  }

  .contact .rightSide h3 {
    font-size: 30px;
    margin: 0;
    padding-bottom: 5px;
  }

  .contact {
    margin-top: 130px;
    margin-bottom: 20px;
  }
}
